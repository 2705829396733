<template>
  <div class="Tree">
    <a-drawer
      :title="alertTitle"
      placement="right"
      :closable="false"
      @close="onClose"
      :visible="visible"
      width="400"
    >
      <a-form layout="vertical" hideRequiredMark>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="菜单名称">
              <a-input
                style="height: 40px;margin-top:5px;"
                v-model="mname"
                placeholder="请输入..."
              />
            </a-form-item>
           	<a-form-model-item label="父级节点">
              <a-cascader style="width: 100%" v-model="choicepids" :options="pidList" placeholder="选择类型" @change="_selectTypeChange"  change-on-select/>
              
            </a-form-model-item>
            <a-form-item label="菜单地址">
              <a-input
                style="height: 40px;margin-top:5px;"
                v-model="url"
                placeholder="请输入..."
              />
            </a-form-item>
            <a-form-item label="排序">
              <a-input
                style="height: 40px;margin-top:5px;"
                v-model="sort"
                placeholder="请输入..."
              />
            </a-form-item>
            <a-form-item label="图标名称">
              <a-input
                style="height: 40px;margin-top:5px;"
                v-model="Ico"
                placeholder="请输入..."
              />
            </a-form-item>
            <a-form-item label="图标上传">
              <img
                v-if="icoImg && icoImg[0].FileUrl != ''"
                :src="icoImg[0].FileUrl"
                style="width: 40px;height: 40px;display: inline-block"
                alt=""
              />
              <div class="upload-btn">
                <span>+</span>
                <input type="file" id="icon" @change="_upload" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="drawer-bottom">
        <a-radio-button
          value="default"
          style="width: 120px;text-align: center;margin-right: 10px"
          @click="_hide"
          >取消</a-radio-button
        >
        <a-button
          @click="_save"
          type="primary"
          style="width: 120px;text-align: center"
          >{{ indexType == 'set' ? '修改' : '确认' }}</a-button
        >
      </div>
    </a-drawer>
    <div>
      <div
        style="box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);display: flex;height: 100%;"
      >
        <div class="classify">
          <div class="classify-header">菜单管理</div>
          <ul class="classify-list">
            <li
              v-for="(item, index) in classifyData"
              @click="_mouseActive(index)"
              :class="mouseActive == index ? 'ant-menu-item-selected' : ''"
              :style="mouseActive == index ? 'background:#f8f9fa' : ''"
            >
              <div
                class="border-left"
                :class="mouseActive == index ? 'ant-btn-primary' : ''"
              ></div>
              <div
                style="display: flex;justify-content: space-between;align-items: center;width: 100%;"
              >
                <span>
                  <a-icon type="appstore" /> <span>{{ item.Mname }}</span>
                </span>
                <div
                  v-show="mouseActive == index"
                  style="display: flex;align-items: center;"
                >
                  <a-icon
                    type="plus-circle"
                    class="icon-btn ant-menu-item-selected"
                    @click="
                      _select(
                        classifyData[mouseActive],
                        classifyData[mouseActive].Id,
                        classifyData[mouseActive].Fid,
                        []
                      )
                    "
                  />
                  <a-icon
                    type="form"
                    class="icon-btn ant-menu-item-selected"
                    @click="
                      _select(item, item.Id, item.Fid, [index], 'setClassif')
                    "
                  />
                  <a-popconfirm
                    placement="top"
                    cancelText="取消"
                    okText="确定"
                    :visible="maxClassify == index"
                    @confirm="_removeClassify(item.Id, index)"
                    @cancel="maxClassify = -1"
                  >
                    <template slot="title">
                      <p>确定删除么？</p>
                    </template>
                    <a-icon
                      type="delete"
                      class="icon-btn ant-menu-item-selected"
                      style="margin-left:9px"
                      @click.stop="_maxClassify"
                    />
                  </a-popconfirm>
                </div>
              </div>
            </li>
          </ul>
          <div style="margin: 20px 15px;">
            <a-button
              type="dashed"
              style="height: 40px"
              block
              @click="_select('添加分类')"
              >+ 添加分类</a-button
            >
          </div>
        </div>

        <div class="classify classify-item" v-if="classifyData.length != 0">
          <div class="classify-header">
            {{ classifyData[mouseActive].Mname }}
          </div>
          <div style="position: relative">
            <ul class="classify-item-list">
              <template v-for="(item, i) in classifyData[mouseActive].child">
                <li
                  @mouseenter="_showIndex(i)"
                  @click.stop="_select(item, item.Id, item.Fid, [i], 'set')"
                >
                  <div class="list-item-content">
                    <div class="border-left ant-btn-primary"></div>
                    <span>{{ item.Mname }}</span>
                    <div
                      v-show="showIndex == i"
                      style="display: flex;align-items: center;"
                    >
                      <a-icon
                        type="plus-circle"
                        class="icon-btn ant-menu-item-selected"
                        @click.stop="
                          _select(item, item.Id, item.Fid, [i], 'add')
                        "
                      />
                      <a-popconfirm
                        placement="top"
                        cancelText="取消"
                        okText="确定"
                        :visible="popconfirm == i"
                        @confirm="_removeClassify(item.Id, [i])"
                        @cancel="popconfirm = -1"
                      >
                        <template slot="title">
                          <p>确定删除么？</p>
                        </template>
                        <a-icon
                          type="delete"
                          class="icon-btn ant-menu-item-selected"
                          @click.stop="_popconfirm"
                        />
                      </a-popconfirm>
                    </div>
                  </div>
                </li>
                <template v-for="(tag, j) in item.child">
                  <li
                    style="padding-left: 80px"
                    @mouseenter="_showIndex(i + '-' + j)"
                    @click.stop="_select(tag, tag.Id, tag.Fid, [i, j], 'set')"
                  >
                    <div class="list-item-content">
                      <span>{{ tag.Mname }}</span>
                      <div
                        v-show="showIndex == i + '-' + j"
                        style="display: flex;align-items: center;"
                      >
                        <a-icon
                          type="plus-circle"
                          class="icon-btn ant-menu-item-selected"
                          @click.stop="
                            _select(tag, tag.Id, tag.Fid, [i, j], 'add')
                          "
                        />
                        <a-popconfirm
                          placement="top"
                          cancelText="取消"
                          okText="确定"
                          :visible="popconfirm == i + '-' + j"
                          @confirm="_removeClassify(tag.Id, [i, j])"
                          @cancel="popconfirm = -1"
                        >
                          <template slot="title">
                            <p>确定删除么？</p>
                          </template>
                          <a-icon
                            type="delete"
                            class="icon-btn ant-menu-item-selected"
                            @click.stop="_popconfirm"
                          />
                        </a-popconfirm>
                      </div>
                    </div>
                  </li>

                  <template v-for="(tag2, k) in tag.child">
                    <li
                      style="padding-left: 140px"
                      @mouseenter="_showIndex(i + '-' + j + '-' + k)"
                      @click.stop="
                        _select(tag2, tag2.Id, tag2.Fid, [i, j, k], 'set')
                      "
                    >
                      <div class="list-item-content">
                        <span>{{ tag2.Mname }}</span>
                        <div
                          v-show="showIndex == i + '-' + j + '-' + k"
                          style="display: flex;align-items: center;"
                        >
                          <a-popconfirm
                            placement="top"
                            cancelText="取消"
                            okText="确定"
                            :visible="popconfirm == i + '-' + j + '-' + k"
                            @confirm="_removeClassify(tag2.Id, [i, j, k])"
                            @cancel="popconfirm = -1"
                          >
                            <template slot="title">
                              <p>确定删除么？</p>
                            </template>
                            <a-icon
                              type="delete"
                              class="icon-btn ant-menu-item-selected"
                              @click.stop="_popconfirm"
                            />
                          </a-popconfirm>
                        </div>
                      </div>
                    </li>
                  </template>
                </template>
              </template>
            </ul>
            <template
              v-if="
                classifyData[mouseActive].child &&
                  classifyData[mouseActive].child.length != 0
              "
            >
              <div class="vertical-line"></div>
              <div class="vertical-line-2"></div>
              <div class="vertical-line-3"></div>
            </template>
          </div>

          <div
            class="empty"
            v-show="
              !classifyData[mouseActive].child ||
                classifyData[mouseActive].child.length == 0
            "
          >
            暂无数据
          </div>
          <!-- <div class="add-classify ant-btn-primary" @click="_select(classifyData[mouseActive].title,classifyData[mouseActive].Id,classifyData[mouseActive].Pid,[])">+</div>  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tree',
  data () {
    return {
      listData: '',
      mouseActive: 0,
      treeData: [],
      visible: false,
      tabActive: 0,
      drawerTitle: '',
      infoMessage: '',
      showInfoMsg: false,
      childrenName: '',
      parentId: '',
      selectData: '',
      classifyData: [],
      alertTitle: '',
      id: '',
      pid: '',
      indexList: [],
      showIndex: -1,
      indexType: '',
      popconfirm: -1,
      maxClassify: -1,
      pidList:[],
      choicepids:[],
      mname: '',
      url: '',
      sort: '',
      icoImg: '',
      Ico: ''
    }
  },
  created () {
    this.$message.loading('加载中...')
    this.$axios.get(1213, {}, res => {
      if (res.data.code === 1) {
        this.listData = res.data.data
        this.classifyData = res.data.data
      }
      this.$message.destroy()
    })
    	this.$axios.get(81800001, {
		 
			}, res => {
				if (res.data.code == 1) {
					this.pidList = res.data.data

				}
			})
  },
  methods: {
     _selectTypeChange(value,selectedOptions){
                //this.choicepids = value;
          console.log(value)
          if(value.length>1){
              this.pid=value[1]
          }else{
              this.pid=value[0]
          }
           
      },
    _upload () {
      let files = document.getElementById('icon').files
      this.__uploadFile__(files).then(res => {
        this.icoImg = res
      })
    },
    _popconfirm () {
      this.popconfirm = this.showIndex
    },
    _showIndex (i) {
      this.showIndex = i
      this.popconfirm = -1
    },
    _removeClassify (id, i) {
      let self = this
      this.$axios.post(1218, { id: id }, res => {
        if (res.data.code === 1) {
          if (Array.isArray(i)) {
            if (i.length == 1) {
              self.classifyData[[self.mouseActive]].child.splice(i[0], 1)
            } else if (i.length == 2) {
              self.classifyData[[self.mouseActive]].child[i[0]].child.splice(
                i[1],
                1
              )
            } else if (i.length == 3) {
              self.classifyData[[self.mouseActive]].child[i[0]].child[
                i[1]
              ].child.splice(i[2], 1)
            }
          } else {
            if (i === this.classifyData.length - 1) {
              this.mouseActive = i - 1
            }
            this.classifyData.splice(i, 1)
          }
          self.$store.commit('TOGGLE_PAGE_MENU_LIST', self.classifyData)
          self.maxClassify = -1
          self.$message.success('删除成功')
        } else {
          self.$message.error(res.data.msg)
        }
      })
    },
    _maxClassify () {
      this.maxClassify = this.mouseActive
    },
    _mouseActive (i) {
      this.mouseActive = i
      this.maxClassify = -1
    },
    _save () {
      let self = this
      if (this.indexType == 'set' || this.indexType == 'setClassif') {
        self.$axios.post(
          1217,
          {
            id: this.id,
            mname: this.mname,
            url: this.url,
            fid: this.pid ? this.pid : '',
            status: 1,
            sort: this.sort,
            ico: this.Ico,
            icoImg: this.icoImg ? this.icoImg[0].FileUrl : ''
          },
          res => {
            if (res.data.code == 1) {
              self.$message.success('操作成功')
              if (self.indexList.length == 0) {
                self.classifyData[self.mouseActive].Mname = this.mname
                self.classifyData[self.mouseActive].Url = this.url
                self.classifyData[self.mouseActive].IcoImg = this.icoImg
                  ? this.icoImg[0].FileUrl
                  : ''
                self.classifyData[self.mouseActive].Sort = this.sort
                self.classifyData[self.mouseActive].Ico = this.Ico
              } else if (self.indexList.length == 1) {
                if (this.indexType == 'setClassif') {
                  self.classifyData[self.mouseActive].Mname = this.mname
                  self.classifyData[self.mouseActive].Url = this.url
                  self.classifyData[self.mouseActive].IcoImg = this.icoImg
                    ? this.icoImg[0].FileUrl
                    : ''
                  self.classifyData[self.mouseActive].Sort = this.sort
                  self.classifyData[self.mouseActive].Ico = this.Ico
                } else {
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].Mname = this.mname
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].Url = this.url
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].IcoImg = this.icoImg ? this.icoImg[0].FileUrl : ''
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].Sort = this.sort
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].Ico = this.Ico
                }
              } else if (self.indexList.length == 2) {
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].Mname = this.mname
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].Url = this.url
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].IcoImg = this.icoImg
                  ? this.icoImg[0].FileUrl
                  : ''
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].Sort = this.sort
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].Ico = this.Ico
              } else if (self.indexList.length == 3) {
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].child[
                  self.indexList[2]
                ].Mname = this.mname
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].child[
                  self.indexList[2]
                ].Mname = this.mname
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].child[
                  self.indexList[2]
                ].Url = this.url
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].child[
                  self.indexList[2]
                ].IcoImg = this.icoImg ? this.icoImg[0].FileUrl : ''
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].child[
                  self.indexList[2]
                ].Sort = this.sort
                self.classifyData[self.mouseActive].child[
                  self.indexList[0]
                ].child[self.indexList[1]].child[
                  self.indexList[2]
                ].Ico = this.Ico
              }
              self.visible = false
              self.id = ''
              self.pid = ''
              self.indexList = ''
              self.mname = ''
              self.url = ''
              self.sort = ''
              self.icoImg = ''
              self.Ico = ''
              self.childrenName = ''
              self.$store.commit('TOGGLE_PAGE_MENU_LIST', self.classifyData)
              location.reload()
            } else {
              self.$message.error(res.data.msg)
            }
          }
        )
      } else {
        self.$axios.post(
          1216,
          {
            mname: this.mname,
            url: this.url,
            fid: this.id ? this.id : '',
            status: 1,
            sort: this.sort,
            ico: this.Ico,
            icoImg: this.icoImg ? this.icoImg[0].FileUrl : ''
          },
          res => {
            if (res.data && res.data.code == 1) {
              self.$message.success('操作成功')
              let item = {
                Pid: this.id,
                Mname: self.mname,
                Id: res.data.Id,
                Url: this.url,
                IcoImg: this.icoImg ? this.icoImg[0].FileUrl : '',
                Sort: this.sort,
                Ico: this.Ico,
                child: []
              }
              if (self.indexList == undefined || self.indexList == null) {
                self.classifyData.push(item)
              } else {
                if (self.indexList.length == 0) {
                  self.classifyData[self.mouseActive].child.push(item)
                } else if (self.indexList.length == 1) {
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].child.push(item)
                } else if (self.indexList.length == 2) {
                  self.classifyData[self.mouseActive].child[
                    self.indexList[0]
                  ].child[self.indexList[1]].child.push(item)
                }
              }
              self.visible = false
              self.id = '';
              self.pid = '';
              self.indexList = '';
              self.mname = '';
              self.url = '';
              self.sort = '';
              self.icoImg = '';
              self.Ico = '';
              self.childrenName = ''
              self.$store.commit('TOGGLE_PAGE_MENU_LIST', self.classifyData)
            } else {
              let msg = res.data ? res.data.msg : '系统错误'
              self.$message.error(msg)
            }
          }
        )
      }
    },
    _hide () {
      this.visible = false;
      this.id = '';
      this.pid = '';
      this.indexList = '';
      this.drawerTitle = '';
      this.childrenName = '';
      this.Ico = '';
      this.mname = '';
      this.url = '';
      this.sort = '';
      this.icoImg = '';
    },
    _tabChange (activeKey) {
      this.$axios.get(
        1200,
        {
          pid: this.listData[activeKey].Id
        },
        res => {
          if (res.data.code === 1) {
            this.treeData = res.data.data
            this.showInfoMsg = false
          } else {
            this.treeData = []
            this.showInfoMsg = true
            this.infoMessage = res.data.msg
          }
        }
      )
    },
    _select (name, id, pid, list, type) {
      if (type == 'set' || type == 'setClassif') {
        this.mname = name.Mname
        this.url = name.Url
        this.sort = name.Sort
        this.icoImg = [{ FileUrl: name.IcoImg }]
        this.Ico = name.Ico
      }
      this.alertTitle = name.Mname
      this.id = id
      this.pid = pid
      this.visible = true
      this.indexList = list
      this.indexType = type
    // console.log(this.pid)
    //  console.log(this.parentId)
      this.choicepids=[this.pid]
    },
    onClose () {
        this.visible = false;
        this.id = '';
        this.pid = '';
        this.indexList = '';
        this.drawerTitle = '';
        this.childrenName = '';
        this.Ico = '';
        this.mname = '';
        this.url = '';
        this.sort = '';
        this.icoImg = '';
    }
  }
}
</script>

<style scoped lang="less">
.Tree {
  display: flex;
  padding-top: 0;
  height: 100%;
}
.icon-btn {
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
}
.upload-btn {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  cursor: pointer;
  span {
    transform: translate(-50%, -50%);
    font-size: 35px;
    line-height: 30px;
    color: #666;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.classify {
  width: 350px;
  overflow-y: scroll;
  background: #fff;
  .classify-header {
    height: 60px;
    line-height: 60px;
    padding-left: 30px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 18px;
  }
  .classify-list {
    margin-top: 10px;
    li {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      font-size: 16px;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: inline-block;
        padding-left: 10px;
      }
      .border-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
      }
    }
  }
}
.classify-item {
  width: 450px;
  overflow-y: scroll;
  background: #f8f9fa;
  position: relative;
  .classify-header {
    width: 100%;
    border-bottom: 1px solid #e0e1e2;
    background: #f8f9fa;
  }
  .classify-item-list {
    margin-top: 10px;
    width: 100%;
    position: relative;
    z-index: 100;
    li {
      width: 100%;
      height: 45px;
      padding: 0 30px 0 30px;
      margin-bottom: 10px;
      cursor: pointer;
      .list-item-content {
        position: relative;
        width: 100%;
        height: 45px;
        line-height: 45px;
        padding: 0 20px;
        padding-right: 10px;
        border: 1px solid #e0e1e2;
        border-radius: 3px;
        background: #fff;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .border-left {
          position: absolute;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
          width: 2px;
          height: 100%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
  .vertical-line {
    position: absolute;
    top: 0;
    left: 50px;
    width: 2px;
    min-height: 100%;
    background: #e0e1e2;
  }
  .vertical-line-2 {
    position: absolute;
    top: 0;
    left: 110px;
    width: 2px;
    min-height: 100%;
    background: #e0e1e2;
  }
  .vertical-line-3 {
    position: absolute;
    top: 0;
    left: 170px;
    width: 2px;
    min-height: 100%;
    background: #e0e1e2;
  }
}
.drawer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: left;
}
.empty {
  font-size: 16px;
  color: #aaa;
  text-align: center;
  padding: 0.2rem;
  margin-top: 15px;
}
.add-classify {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  z-index: 1000;
}
</style>
